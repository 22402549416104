<template>
 <horizontalScreen v-show="showToast" class="mask"> 
    <transition name="toast">
      <div v-show="showToast" class="my-toast">{{msg}}</div>
    </transition>
 </horizontalScreen>
</template>

<script>
import horizontalScreen from '@/components/horizontal-screen'

export default {
  components: {horizontalScreen},
  props:{
    msg: String,
    duration: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      showToast: false
    }
  }
}
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
.my-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 20px 50px;
  color: #fff;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 9999;
  text-align: center;
  font-size: 26px;
  border-radius: 16px;
  transform-origin: center center;
}
.toast-enter-active, .toast-leave-active {
  transition: .3s ease-out;
  transform-origin: center center;
}
.toast-enter {
  opacity: 0;
  // transform: scale(1.2);
}
.toast-leave-to {
  opacity: 0;
  // transform: scale(0.8);
}
</style>