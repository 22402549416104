<template>
  <horizontalScreen class="zy-loading-content" v-if="show">
      <div class="loading-spinner">
        <svg viewBox="42 42 84 84" class="circular">
          <circle cx="84" cy="84" r="30" fill="none" class="path"></circle>
        </svg>
      </div>
      <div class="loading-text">{{ text }}</div>
  </horizontalScreen>
</template>

<script>
import horizontalScreen from '@/components/horizontal-screen'
export default {
  components: {horizontalScreen},
  name: "loading",
  props: {
    show: Boolean,
    text: {
      type: String,
      default: "加载中。。。",
    },
  },
};
</script>

<style>
.zy-loading-content {
  position: absolute;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-spinner {
  text-align: center;
}
.loading-spinner .circular {
  height: 84px;
  width: 84px;
  animation: loading-rotate 2s linear infinite;
}
.loading-spinner .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: #f95160;
  stroke-linecap: round;
}
.loading-text {
  font-size: 24px;
  color: #f95160;
}
@keyframes loading-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }

  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
</style>
