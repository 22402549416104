const gameRouter = [
  {
    path: 'gameIndex',
    name: 'CameIndex',
    component: () => import(/* webpackChunkName: "game" */ '../views/game/Index.vue'),
  },
  {
    path: 'game1',
    name: 'Game1',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-1.vue'),
  },
  {
    path: 'game2',
    name: 'Game2',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-2.vue'),
  },
  {
    path: 'game3',
    name: 'Game3',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-3.vue'),
  },
  {
    path: 'game4',
    name: 'Game4',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-4.vue'),
  },
  {
    path: 'game5',
    name: 'Game5',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-5.vue'),
  },
  {
    path: 'game6',
    name: 'Game6',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-6.vue'),
  },
  {
    path: 'game7',
    name: 'Game7',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-7.vue'),
  },
  {
    path: 'game8',
    name: 'Game8',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-8.vue'),
  },
  {
    path: 'game9',
    name: 'Game9',
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1-9.vue'),
  },
  {
    path: 'game10',
    name: 'Game10',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-1.vue'),
  },
  {
    path: 'game11',
    name: 'Game11',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-2.vue'),
  },
  {
    path: 'game12',
    name: 'Game12',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-3.vue'),
  },
  {
    path: 'game13',
    name: 'Game13',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-4.vue'),
  },
  {
    path: 'game14',
    name: 'Game14',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-5.vue'),
  },
  {
    path: 'game15',
    name: 'Game15',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-6.vue'),
  },
  {
    path: 'game16',
    name: 'Game16',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-7.vue'),
  },
  {
    path: 'game17',
    name: 'Game17',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-8.vue'),
  },
  {
    path: 'game18',
    name: 'Game18',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-9.vue'),
  },
  {
    path: 'game19',
    name: 'Game19',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-10.vue'),
  },
  {
    path: 'game20',
    name: 'Game20',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-11.vue'),
  },
  {
    path: 'game21',
    name: 'Game21',
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2-12.vue'),
  },
  {
    path: 'game22',
    name: 'Game22',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-1.vue'),
  },
  {
    path: 'game23',
    name: 'Game23',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-2.vue'),
  },
  {
    path: 'game24',
    name: 'Game24',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-3.vue'),
  },
  {
    path: 'game25',
    name: 'Game25',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-4.vue'),
  },
  {
    path: 'game26',
    name: 'Game26',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-5.vue'),
  },
  {
    path: 'game27',
    name: 'Game27',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-6.vue'),
  },
  {
    path: 'game28',
    name: 'Game28',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-7.vue'),
  },
  {
    path: 'game29',
    name: 'Game29',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-8.vue'),
  },
  {
    path: 'game30',
    name: 'Game30',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-9.vue'),
  },
  {
    path: 'game31',
    name: 'Game31',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-10.vue'),
  },
  {
    path: 'game32',
    name: 'Game32',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-11.vue'),
  },
  {
    path: 'game33',
    name: 'Game33',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-12.vue'),
  },
  {
    path: 'game34',
    name: 'Game34',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-13.vue'),
  },
  {
    path: 'game35',
    name: 'Game35',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-14.vue'),
  },
  {
    path: 'game36',
    name: 'Game36',
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3-15.vue'),
  },
  {
    path: 'game37',
    name: 'Game37',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-1.vue'),
  },
  {
    path: 'game38',
    name: 'Game38',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-2.vue'),
  },
  {
    path: 'game39',
    name: 'Game39',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-3.vue'),
  },
  {
    path: 'game40',
    name: 'Game40',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-4.vue'),
  },
  {
    path: 'game41',
    name: 'Game41',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-5.vue'),
  },
  {
    path: 'game42',
    name: 'Game42',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-6.vue'),
  },
  {
    path: 'game43',
    name: 'Game43',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-7.vue'),
  },
  {
    path: 'game44',
    name: 'Game44',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-8.vue'),
  },
  {
    path: 'game45',
    name: 'Game45',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-9.vue'),
  },
  {
    path: 'game46',
    name: 'Game46',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-10.vue'),
  },
  {
    path: 'game47',
    name: 'Game47',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-11.vue'),
  },
  {
    path: 'game48',
    name: 'Game48',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-12.vue'),
  },
  {
    path: 'game49',
    name: 'Game49',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-13.vue'),
  }, 
  {
    path: 'game50',
    name: 'Game50',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-14.vue'),
  },
  {
    path: 'game51',
    name: 'Game51',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-15.vue'),
  },
  {
    path: 'game52',
    name: 'Game52',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-16.vue'),
  },
  {
    path: 'game53',
    name: 'Game53',
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4-17.vue'),
  },
  {
    path: 'game54',
    name: 'Game54',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-1.vue'),
  },
  {
    path: 'game55',
    name: 'Game55',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-2.vue'),
  },
  {
    path: 'game56',
    name: 'Game56',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-3.vue'),
  },
  {
    path: 'game57',
    name: 'Game57',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-4.vue'),
  },
  {
    path: 'game58',
    name: 'Game58',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-5.vue'),
  },
  {
    path: 'game59',
    name: 'Game59',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-6.vue'),
  },
  {
    path: 'game60',
    name: 'Game60',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-7.vue'),
  },
  {
    path: 'game61',
    name: 'Game61',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-8.vue'),
  },
  {
    path: 'game62',
    name: 'Game62',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-9.vue'),
  },
  {
    path: 'game63',
    name: 'Game63',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-10.vue'),
  },
  {
    path: 'game64',
    name: 'Game64',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-11.vue'),
  },
  {
    path: 'game65',
    name: 'Game65',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-12.vue'),
  },
  {
    path: 'game66',
    name: 'Game66',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-13.vue'),
  },
  {
    path: 'game67',
    name: 'Game67',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-14.vue'),
  },
  {
    path: 'game68',
    name: 'Game68',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-15.vue'),
  },
  {
    path: 'game69',
    name: 'Game69',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-16.vue'),
  },
  {
    path: 'game70',
    name: 'Game70',
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5-17.vue'),
  },
  {
    path: 'game71',
    name: 'Game71',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-1.vue'),
  },
  {
    path: 'game72',
    name: 'Game72',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-2.vue'),
  },
  {
    path: 'game73',
    name: 'Game73',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-3.vue'),
  },
  {
    path: 'game74',
    name: 'Game74',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-4.vue'),
  },
  {
    path: 'game75',
    name: 'Game75',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-5.vue'),
  },
  {
    path: 'game76',
    name: 'Game76',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-6.vue'),
  },
  {
    path: 'game77',
    name: 'Game77',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-7.vue'),
  },
  {
    path: 'game78',
    name: 'Game78',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-8.vue'),
  },
  {
    path: 'game79',
    name: 'Game79',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-9.vue'),
  },
  {
    path: 'game80',
    name: 'Game80',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-10.vue'),
  },
  {
    path: 'game81',
    name: 'Game81',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-11.vue'),
  },
  {
    path: 'game82',
    name: 'Game82',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-12.vue'),
  },
  {
    path: 'game83',
    name: 'Game83',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-13.vue'),
  },
  {
    path: 'game84',
    name: 'Game84',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-14.vue'),
  },
  {
    path: 'game85',
    name: 'Game85',
    component: () => import(/* webpackChunkName: "game6" */ '../views/game/Game6-15.vue'),
  }
]

export default gameRouter