import Vue from 'vue'
import VueRouter from 'vue-router'
import gameRouter from './gameRouter'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: {name: 'Index'}
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "book" */ '../views/Index.vue')
  },
  {
    path: '/book',
    name: 'Book',
    component: () => import(/* webpackChunkName: "book" */ '../views/Book.vue')
  },
  {
    path: '/word',
    component: () => import(/* webpackChunkName: "word" */ '../BaseRouter.vue'),
    children: [
      {
        path: 'wordIndex',
        name: 'WordIndex',
        component: () => import(/* webpackChunkName: "word" */ '../views/word/Index.vue'),
      },
      {
        path: 'wordCourse',
        name: 'WordCourse',
        component: () => import(/* webpackChunkName: "word" */ '../views/word/Course.vue'),
      }
    ]
  },
  {
    path: '/read',
    component: () => import(/* webpackChunkName: "read" */ '../BaseRouter.vue'),
    children: [
      {
        path: 'readIndex',
        name: 'ReadIndex',
        component: () => import(/* webpackChunkName: "read" */ '../views/read/Index.vue'),
      },
      {
        path: 'readCourse',
        name: 'ReadCourse',
        component: () => import(/* webpackChunkName: "read" */ '../views/read/Course.vue'),
      }
    ]
  },
  {
    path: '/listenText',
    component: () => import(/* webpackChunkName: "listenText" */ '../BaseRouter.vue'),
    children: [
      {
        path: 'listenTextIndex',
        name: 'ListenTextIndex',
        component: () => import(/* webpackChunkName: "listenText" */ '../views/listenText/Index.vue'),
      },
      {
        path: 'listenTextCourse',
        name: 'ListenTextCourse',
        component: () => import(/* webpackChunkName: "listenText" */ '../views/listenText/Course.vue'),
      }
    ]
  },
  {
    path: '/listenWord',
    component: () => import(/* webpackChunkName: "listenWord" */ '../BaseRouter.vue'),
    children: [
      {
        path: 'listenWordIndex',
        name: 'ListenWordIndex',
        component: () => import(/* webpackChunkName: "listenWord" */ '../views/listenWord/Index.vue'),
      },
      {
        path: 'listenWordCourse',
        name: 'ListenWordCourse',
        component: () => import(/* webpackChunkName: "listenWord" */ '../views/listenWord/Course.vue'),
      }
    ]
  },
  {
    path: '/card',
    component: () => import(/* webpackChunkName: "card" */ '../BaseRouter.vue'),
    children: [
      {
        path: 'cardIndex',
        name: 'CardIndex',
        component: () => import(/* webpackChunkName: "card" */ '../views/card/Index.vue'),
      },
      {
        path: 'cardState',
        name: 'CardState',
        component: () => import(/* webpackChunkName: "card" */ '../views/card/State.vue'),
      },
      {
        path: 'cardCourse',
        name: 'CardCourse',
        component: () => import(/* webpackChunkName: "card" */ '../views/card/Course.vue'),
      }
    ]
  },
  {
    path: '/game',
    component: () => import(/* webpackChunkName: "game" */ '../BaseRouter.vue'),
    children: gameRouter
  },
  {
    path: '/Video',
    name: 'Video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "video" */ '../views/Video.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
