import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/flexible.js'
import myToast from '@/lib/toast'
import Navigation from 'vue-navigation'
import 'font-awesome/css/font-awesome.min.css'
import loading from '@/lib/loading'

Vue.use(myToast)
Vue.use(loading)

// import  FastClick  from  'fastclick'

// FastClick.attach(document.body)
// import VConsole from 'vconsole'
// new VConsole()

Vue.use(Navigation, {router})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
