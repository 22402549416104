export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_LISTEN_TEXT_RADIO = 'SET_LISTEN_TEXT_RADIO'
export const SET_LISTEN_WORD_RADIO = 'SET_LISTEN_WORD_RADIO'
export const SET_ALBUM_ID = 'SET_ALBUM_ID'
export const SET_READING_AUDIO = 'SET_READING_AUDIO'
export const SET_CARD_GRADE_IDS = 'SET_CARD_GRADE_IDS'
export const SET_SCROLL_INDEX = 'SET_SCROLL_INDEX'
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP'
export const SET_ALBUM_LIST = 'SET_ALBUM_LIST'
export const SET_GAME_ROUTER = 'SET_GAME_ROUTER'
export const SET_GAME_LOADED = 'SET_GAME_LOADED'
