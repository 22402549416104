<template>
  <div id="app">
    <navigation>
      <router-view/>
    </navigation>
    <audio id="clickAudio" preload="auto">
      <source :src="this.clickAudio">
    </audio>
  </div>
</template>
<script>
export default {
  mounted() {
    window.addEventListener('resize', function() {
      if(document.querySelector('#app section').style.transform === 'rotate(0deg)') {
         // 横屏  word-container
         document.querySelector('.home').className = 'home'
        // this.isVertical = false
  
      } else {
        document.querySelector('.home').classList.add('home-horizontal')
        // this.isVertical = true
      }
      window.location.reload()
    }, false);
  },
  data() {
    return {
      clickAudio: require(`@/assets/sounds/click.mp3`)
    }
  }
}
</script>
<style lang="scss">
audio {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;
}
.van-toast-class {
  transform: translateX(-50%) translateY(-50%) rotate(90deg)!important;
}
@font-face {
  font-family: 'FZHuPo-M04S';
  src: url('/font/FZHPJW.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  // font-family: 'Alibaba-PuHuiTi-Bold';
  src: url('/font/Alibaba-PuHuiTi-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

#app {
  overflow: hidden;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html,body {
  -webkit-overflow-scrolling: touch; 
  height: 100%;
  width: 100%;
  user-select:none;
  overflow: hidden;
}
* {
  padding: 0;
  margin: 0;
}
.van-overlay {
  background-color: transparent!important;
  z-index: 999!important;
}
img {
  display: block;
}
/* iOS禁止微信调整字体大小 */
body {
    -webkit-text-size-adjust: 100% !important;
    text-size-adjust: 100% !important;
    -moz-text-size-adjust: 100% !important;
}
.mask-title {
  font-weight: bolder;
}
.logo {
  width: 28px;
  left: 5px;
  top: 10PX;
  position: fixed;
  z-index: 70;
}
</style>
