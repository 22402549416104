import http from '@/utils/request.js'

// 绑定激活码 
export const bindingCard = (data) => {
  return http.post('card/bindingCard', data)
}
// 激活列表
export const getCardList = () => {
  return http.get('card/list ')
}
// 查询册
export const getAlbumList = () => {
  return http.get('web/commodity/getAlbumList')
}
// 获取单元
export const getGradeList = (data) => {
  return http.post('web/commodity/getGradeList', data)
}
// 获取字体
export const getFontList = (data) => {
  return http.post('web/commodity/getFontList', data)
}
// 获取阅读
export const getReadingList = (data) => {
  return http.post('web/reading/getReadingList', data)
}
// 获取指定阅读数据
export const getReading = (data) => {
  return http.post('web/reading/getReading', data)
}
// 获取指定阅读数据
export const getGamesList = (data) => {
  return http.post('web/games/getGamesList', data)
}
// 获取游戏文字与语音
export const getGamesFontList = (data) => {
  return http.post('web/games/getGamesFontList', data)
}
// 闪卡列表 
export const getFlashList = (data) => {
  return http.post('web/flash/getFlashList', data)
}
// 闪卡图片 
export const getFlash = (data) => {
  return http.post('web/flash/getFlash', data)
}
// 连听字词列表
export const getListenWordList = (data) => {
  return http.post('web/listenword/getListenWordList', data)
}
// 连听字词
export const getListenWord = (data) => {
  return http.post('web/listenword/getListenWord', data)
}
// 连听课文列表
export const getListenBookList = (data) => {
  return http.post('web/listenbook/getListenBookList', data)
}
// 连听课文
export const getListenBook = (data) => {
  return http.post('web/listenbook/getListenBook', data)
}

export default {
  bindingCard,
  getCardList,
  getAlbumList,
  getGradeList,
  getFontList,
  getReadingList,
  getReading,
  getGamesList,
  getGamesFontList,
  getFlashList,
  getFlash,
  getListenWordList,
  getListenWord,
  getListenBookList,
  getListenBook
}
