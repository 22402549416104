import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './mutation-types'
import api from '@/api'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userId: '',
    albumId: null, // 第几册
    readingAudio: {},
    cardGradeIds: [],
    listenTextRadio: [],
    listenWordRadio: [],
    scrollIndex: null,
    scrollTop: 0,
    albumList: [],
    gameRouter: null,
    gameLoaded: {
      'game1-1': false,
      'game1-2': false,
      'game1-3': false,
      'game1-4': false,
      'game1-5': false,
      'game1-6': false,
      'game1-7': false,
      'game1-8': false,
      'game1-9': false,
      'game2-1': false,
      'game2-2': false,
      'game2-3': false,
      'game2-4': false,
      'game2-5': false,
      'game2-6': false,
      'game2-7': false,
      'game2-8': false,
      'game2-9': false,
      'game2-10': false,
      'game2-11': false,
      'game2-12': false,
      'game3-1': false,
      'game3-2': false,
      'game3-3': false,
      'game3-4': false,
      'game3-5': false,
      'game3-6': false,
      'game3-7': false,
      'game3-8': false,
      'game3-9': false,
      'game3-10': false,
      'game3-11': false,
      'game3-12': false,
      'game3-13': false,
      'game3-14': false,
      'game3-15': false,
      'game4-1': false,
      'game4-2': false,
      'game4-3': false,
      'game4-4': false,
      'game4-5': false,
      'game4-6': false,
      'game4-7': false,
      'game4-8': false,
      'game4-9': false,
      'game4-10': false,
      'game4-11': false,
      'game4-12': false,
      'game4-13': false,
      'game4-14': false,
      'game4-15': false,
      'game4-16': false,
      'game4-17': false,
      'game5-1': false,
      'game5-2': false,
      'game5-3': false,
      'game5-4': false,
      'game5-5': false,
      'game5-6': false,
      'game5-7': false,
      'game5-8': false,
      'game5-9': false,
      'game5-10': false,
      'game5-11': false,
      'game5-12': false,
      'game5-13': false,
      'game5-14': false,
      'game5-15': false,
      'game5-16': false,
      'game5-17': false,
      'game6-1': false,
      'game6-2': false,
      'game6-3': false,
      'game6-4': false,
      'game6-5': false,
      'game6-6': false,
      'game6-7': false,
      'game6-8': false,
      'game6-9': false,
      'game6-10': false,
      'game6-11': false,
      'game6-12': false,
      'game6-13': false,
      'game6-14': false,
      'game6-15': false
    }
  },
  getters: {
    token: state => state.token,
    userId: state => state.userId,
    albumId: state => state.albumId,
    albumName: state => {
      const albumObj = {
        1: '第一册',
        2: '第二册',
        3: '第三册',
        4: '第四册',
        5: '第五册',
        6: '第六册',
      }
      return albumObj[state.albumId]
    },
    cardGradeIds: state => state.cardGradeIds,
    readingAudio: state => state.readingAudio,
    listenTextRadio: state => state.listenTextRadio,
    listenWordRadio: state => state.listenWordRadio,
    scrollIndex: state => state.scrollIndex,
    scrollTop: state => state.scrollTop,
    albumList: state => state.albumList,
    gameRouter: state => state.gameRouter,
    gameLoaded: state => state.gameLoaded
  },
  mutations: {
    [types.SET_USER_INFO](state, payload) {
      state.token = payload.token
      state.userId = payload.userId
    },
    [types.SET_LISTEN_TEXT_RADIO](state, payload) {
      state.listenTextRadio = payload
    },
    [types.SET_LISTEN_WORD_RADIO](state, payload) {
      state.listenWordRadio = payload
    },
    [types.SET_ALBUM_ID](state, payload) {
      state.albumId = payload
    },
    [types.SET_READING_AUDIO](state, payload) {
      state.readingAudio = payload
    },
    [types.SET_CARD_GRADE_IDS](state, payload){
      state.cardGradeIds = payload
    },
    [types.SET_SCROLL_INDEX](state, payload){
      state.scrollIndex = payload
    },
    [types.SET_SCROLL_TOP](state, payload){
      state.scrollTop = payload
    },
    [types.SET_ALBUM_LIST](state, payload) {
      state.albumList = payload
    },
    [types.SET_GAME_ROUTER](state, payload) {
      state.gameRouter = payload
    },
    [types.SET_GAME_LOADED](state, payload) {
      state.gameLoaded = {
        ...state.gameLoaded,
        ...payload
      }
    }
  },
  actions: {
    // 获取册
    async getAlbumList(store, payload) {
      const {code, data} = await api.getAlbumList(payload)
      if (code == 1 && data.result.length) {
        store.commit(types.SET_ALBUM_LIST, data.result)
        return data.result
      }
    },
    // 获取单元
    async getGradeList(store, payload) {
      const {code, data} = await api.getGradeList(payload)
      if (code == 1) {
        return data
      }
    },
    // 获取字体
    async getFontList(store, payload) {
      const {code, data} = await api.getFontList(payload)
      if (code == 1) {
        return data
      }
    },
    // 获取阅读
    async getReadingList(store, payload) {
      const {code, data} = await api.getReadingList(payload)
      if (code == 1) {
        return data
      }
    },
    // 阅读详情
    async getReading(store, payload) {
      const {code, data} = await api.getReading(payload)
      if (code == 1) {
        return data
      }
    },
    // 游戏列表
    async getGamesList(store, payload) {
      const {code, data} = await api.getGamesList(payload)
      if (code == 1) {
        return data
      }
    },
    async getGamesFontList(store, payload) {
      const {code, data} = await api.getGamesFontList(payload)
      if (code == 1 && data.result.length) {
        data.result = data.result.map(item => {
          return {
            id: item.fontId,
            name: item.fontName,
            icon: item.fontUrl,
            voice: item.gameUrl
          }
        })
        return data 
      }

    }, 
    // 闪卡列表
    async getFlashList(store, payload) {
      const {code, data} = await api.getFlashList(payload)
      if (code == 1) {
        return data
      }
    },
    // 闪卡图片
    async getFlash(store, payload) {
      const {code, data} = await api.getFlash(payload)
      if (code == 1) {
        return data
      }
    },
    // 连听字词列表
    async getListenWordList(store, payload) {
      const {code, data} = await api.getListenWordList(payload)
      if (code == 1) {
        return data
      }
    },
    // 连听字词
    async getListenWord(store, payload) {
      const {code, data} = await api.getListenWord(payload)
      if (code == 1) {
        return data
      }
    },
    // 连听课文列表
    async getListenBookList(store, payload) {
      const {code, data} = await api.getListenBookList(payload)
      if (code == 1) {
        return data
      }
    },
    // 连听课文
    async getListenBook(store, payload) {
      const {code, data} = await api.getListenBook(payload)
      if (code == 1) {
        return data
      }
    }

  },
  modules: {
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
